<template>
  <div class="roles-main">
    <div class="header-container">
      <div class="search">
        <el-button
          style="margin-left: 20px"
          icon="el-icon-refresh"
          size="mini"
          type="success"
          @click="refresh()"
        />

        <el-button
          size="mini"
          type="primary"
          v-permission="['sys:report:privilege:member:detail:export']"
          @click="requestExportExcel"
        >
          {{ t('fields.requestExportToExcel') }}
        </el-button>
      </div>
    </div>

    <el-table
      :data="page.records"
      ref="table"
      row-key="id"
      size="small"
      highlight-current-row
      v-loading="page.loading"
      height="500"
      :empty-text="t('fields.noData')"
    >
      <el-table-column
        prop="memberName"
        :label="t('fields.memberName')"
        width="120"
        fixed
      />
      <el-table-column
        prop="realName"
        :label="t('fields.realName')"
        width="120"
        fixed
      />
      <el-table-column
        prop="totalBonus"
        :label="t('fields.totalBonus')"
        width="120"
      >
        <template #default="scope1">
          $
          <span
            v-formatter="{
              data: scope1.row.totalBonus,
              type: 'money',
            }"
          />
        </template>
      </el-table-column>

      <el-table-column
        prop="totalDeposit"
        :label="t('fields.totalDeposit')"
        width="120"
      >
        <template #default="scope1">
          $
          <span
            v-formatter="{
              data: scope1.row.totalDeposit,
              type: 'money',
            }"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="totalDepositCount"
        :label="t('fields.totalDepositCount')"
        width="150"
      />
      <el-table-column
        prop="totalWithdraw"
        :label="t('fields.totalWithdraw')"
        width="120"
      >
        <template #default="scope1">
          $
          <span
            v-formatter="{
              data: scope1.row.totalWithdraw,
              type: 'money',
            }"
          />
        </template>
      </el-table-column>
      <el-table-column :label="t('fields.action')" width="180">
        <template #default="scope1">
          <el-button
            style="margin-left: 5px"
            icon="el-icon-phone"
            size="mini"
            type="success"
            v-if="siteIdFromParam === '3'"
            v-permission="['sys:member:call:phone']"
            @click="callPhone(scope1.row)"
          />
          <el-button
            style="margin-left: 5px"
            icon="el-icon-video-pause"
            size="mini"
            type="danger"
            v-if="siteIdFromParam === '3'"
            v-permission="['sys:member:stop:phone']"
            @click="stopPhone(scope1.row)"
          />
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="pagination"
      @current-change="changePage"
      layout="prev, pager, next"
      :page-size="request.size"
      :page-count="page.pages"
      :current-page="request.current"
    />

    <el-dialog
      :title="t('fields.exportToExcel')"
      v-model="uiControl.messageVisible"
      append-to-body
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span>{{ t('message.requestExportToExcelDone1') }}</span>
      <router-link :to="`/site-management/download-manager`">
        <el-link type="primary">
          {{ t('menu.DownloadManager') }}
        </el-link>
      </router-link>
      <span>{{ t('message.requestExportToExcelDone2') }}</span>
    </el-dialog>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue'
import {
  getPrivilegeMemberDetailReport,
  getExportPrivilegeMemberDetailReport,
} from '../../../../api/report-privilege'
import { getSiteListSimple } from '../../../../api/site'
import { callTelephone, stopTelephone } from "../../../../api/vcall";
import { useStore } from '../../../../store'
import { TENANT } from '../../../../store/modules/user/action-types'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
import { ElMessage } from "element-plus";

// eslint-disable-next-line
const { t } = useI18n()
const store = useStore()
const LOGIN_USER_TYPE = computed(() => store.state.user.userType)
const site = ref(null)
const siteList = reactive({
  list: [],
})
var id = new URL(location.href).searchParams.get('id')
var date = new URL(location.href).searchParams.get('date')
var siteIdFromParam = new URL(location.href).searchParams.get('site')

const uiControl = reactive({
  messageVisible: false,
})

const page = reactive({
  pages: 0,
  records: [],
  loading: false,
})

const request = reactive({
  size: 30,
  current: 1,
  recordTime: date,
  siteId: null,
  id: id,
})

async function loadPrivilegeMemberDetail() {
  page.loading = true
  const requestCopy = { ...request }
  const query = {}
  Object.entries(requestCopy).forEach(([key, value]) => {
    if (value) {
      query[key] = value
    }
  })
  if (request.recordTime !== null) {
    if (request.recordTime.length === 2) {
      query.recordTime = request.recordTime.join(',')
    }
  }

  const { data: ret } = await getPrivilegeMemberDetailReport(query)

  page.pages = ret.pages
  page.records = ret.records

  page.loading = false
}

function changePage(page) {
  request.current = page
  loadPrivilegeMemberDetail()
}

async function loadSites() {
  const { data: site } = await getSiteListSimple()
  siteList.list = site
}

function refresh() {
  loadPrivilegeMemberDetail()
}

async function callPhone(row) {
  var res = await callTelephone(row.id, row.siteId)
  console.log(res);
  if (res.data === 'true') {
    ElMessage({ message: t('message.success'), type: 'success' })
  } else {
    ElMessage({ message: t('fields.fail'), type: 'fail' })
  }
}

async function stopPhone(row) {
  var res = await stopTelephone(row.id, row.siteId)
  if (res.data === 'true') {
    ElMessage({ message: t('message.success'), type: 'success' })
  } else {
    ElMessage({ message: t('fields.fail'), type: 'fail' })
  }
}

onMounted(async () => {
  await loadSites()
  // tenant 只可以看到本身site的资料
  if (LOGIN_USER_TYPE.value === TENANT.value) {
    site.value = siteList.list.find(
      s => s.siteName === store.state.user.siteName
    )
    request.siteId = site.value.id
  } else {
    request.siteId = siteIdFromParam
  }

  await loadPrivilegeMemberDetail()
})

function checkQuery() {
  const requestCopy = { ...request }
  const query = {}
  Object.entries(requestCopy).forEach(([key, value]) => {
    if (value) {
      query[key] = value
    }
  })
  if (request.recordTime !== null) {
    if (request.recordTime.length === 2) {
      query.recordTime = request.recordTime.join(',')
    }
  }
  return query
}

async function requestExportExcel() {
  const query = checkQuery()
  query.requestBy = store.state.user.name
  query.requestTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
  const { data: ret } = await getExportPrivilegeMemberDetailReport(query)
  if (ret) {
    uiControl.messageVisible = true
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}
</style>
