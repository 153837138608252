<template>
  <div class="roles-main">
    <div class="header-container">
      <div class="search">
        <el-input
          v-model="request.name"
          size="small"
          style="width: 200px"
          :placeholder="t('fields.imageName')"
        />
        <el-select
          v-model="request.category"
          size="small"
          :placeholder="t('fields.category')"
          class="filter-item"
          style="width: 120px; margin-left: 5px"
        >
          <el-option
            v-for="item in uiControl.category"
            :key="item.name"
            :label="item.display"
            :value="item.name"
          />
        </el-select>

        <el-select
          v-model="request.siteId"
          size="small"
          :placeholder="t('fields.site')"
          class="filter-item"
          style="width: 120px; margin-left: 5px"
        >
          <el-option
            v-for="item in siteList.list"
            :key="item.id"
            :label="item.siteName"
            :value="item.id"
          />
        </el-select>
        <el-button
          style="margin-left: 20px"
          icon="el-icon-search"
          size="mini"
          type="success"
          @click="loadSiteImage"
        >
          {{ t('fields.search') }}
        </el-button>
        <el-button
          icon="el-icon-refresh"
          size="mini"
          type="warning"
          @click="resetQuery()"
        >
          {{ t('fields.reset') }}
        </el-button>
      </div>
      <div class="btn-group">
        <el-button
          icon="el-icon-plus"
          size="mini"
          type="primary"
          v-permission="['sys:siteimage:add']"
          @click="showDialog('CREATE')"
        >
          {{ t('fields.add') }}
        </el-button>
        <el-button
          icon="el-icon-remove"
          size="mini"
          type="danger"
          v-permission="['sys:siteimage:del']"
          @click="removeImage()"
          :disabled="uiControl.removeBtn"
        >
          {{ t('fields.delete') }}
        </el-button>
      </div>
    </div>
    <el-dialog
      :title="uiControl.dialogTitle"
      v-model="uiControl.dialogVisible"
      append-to-body
      width="600px"
      :close-on-press-escape="false"
    >
      <el-form
        ref="imageForm"
        :model="form"
        :rules="formRules"
        :inline="true"
        size="small"
        label-width="180px"
      >
        <div id="preview">
          <el-image
            v-if="uploadedImage.url"
            :src="uploadedImage.url"
            :fit="contain"
            :preview-src-list="[uploadedImage.url]"
          />
        </div>
        <el-form-item :label="t('fields.image')" prop="path">
          <el-row :gutter="10">
            <el-col :span="2">
              <!-- eslint-disable -->
              <input
                id="uploadFile"
                type="file"
                ref="inputImage"
                style="display: none"
                accept="image/*"
                @change="attachImage"
              />
              <el-button
                icon="el-icon-upload"
                size="mini"
                type="success"
                @click="$refs.inputImage.click()"
              >
                {{ t('fields.upload') }}
              </el-button>
            </el-col>
            <el-col :span="1" />
          </el-row>
        </el-form-item>
        <el-form-item :label="t('fields.imageName')" prop="name">
          <el-input v-model="form.name" style="width: 350px" />
        </el-form-item>
        <el-form-item :label="t('fields.category')" prop="category">
          <el-select
            v-model="form.category"
            size="small"
            :placeholder="t('fields.category')"
            class="filter-item"
            style="width: 350px"
            default-first-option
            @change="checkGamePlatform('CREATE')"
          >
            <el-option
              v-for="item in uiControl.category"
              :key="item.name"
              :label="item.display"
              :value="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="t('fields.site')" prop="siteId">
          <el-select
            v-model="form.siteId"
            size="small"
            :placeholder="t('fields.site')"
            class="filter-item"
            style="width: 350px"
            default-first-option
            @focus="loadSites"
            @change="checkGamePlatform('CREATE')"
          >
            <el-option
              v-for="item in siteList.list"
              :key="item.id"
              :label="item.siteName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-show="form.category && form.siteId && form.category === 'GAME'"
          :label="t('fields.platform')"
          prop="platform"
        >
          <el-select
            v-model="form.platform"
            size="small"
            :placeholder="t('fields.platform')"
            class="filter-item"
            style="width: 350px"
          >
            <el-option
              v-for="item in platform.list"
              :key="item.code"
              :label="item.code"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-show="form.category === 'POSTER'"
          :label="t('fields.posterType')"
          prop="posterType"
        >
          <el-select
            v-model="form.posterType"
            size="small"
            :placeholder="t('fields.posterType')"
            class="filter-item"
            style="width: 350px"
            default-first-option
          >
            <el-option
              v-for="item in uiControl.posterType"
              :key="item.name"
              :label="item.display"
              :value="item.name"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          v-show="form.category === 'PROMO'"
          :label="t('fields.promoType')"
          prop="promoType"
        >
          <el-select
            v-model="form.promoType"
            size="small"
            :placeholder="t('fields.promoType')"
            class="filter-item"
            style="width: 350px"
            default-first-option
          >
            <el-option
              v-for="item in uiControl.promoType"
              :key="item.name"
              :label="item.display"
              :value="item.name"
            />
          </el-select>
          <!--insert image size-->
          <div v-if="form.promoType === 'DESKTOP_IMAGE'">
            <span v-if="form.siteId === 1">{{ t('fields.imageSize') }}: 355*180</span>
            <span v-if="form.siteId === 3">{{ t('fields.imageSize') }}: 800*188</span>
            <span v-if="form.siteId === 6">{{ t('fields.imageSize') }}: 355*180</span>
            <span v-if="form.siteId === 7">{{ t('fields.imageSize') }}: 1920*600</span>
          </div>
          <div v-if="form.promoType === 'MOBILE_IMAGE'">
            <span v-if="form.siteId === 1">{{ t('fields.imageSize') }}: 1004*252</span>
            <span v-if="form.siteId === 3">{{ t('fields.imageSize') }}: 1000*454</span>
            <span v-if="form.siteId === 6">{{ t('fields.imageSize') }}: 1004*252</span>
            <span v-if="form.siteId === 7">{{ t('fields.imageSize') }}: 1080*512</span>
          </div>
          <div v-if="form.promoType === 'DESKTOP_BANNER'">
            <span v-if="form.siteId === 1">{{ t('fields.imageSize') }}: 1920*500</span>
            <span v-if="form.siteId === 3">{{ t('fields.imageSize') }}: 2000*500</span>
            <span v-if="form.siteId === 6">{{ t('fields.imageSize') }}: 1920*500</span>
            <span v-if="form.siteId === 7">{{ t('fields.imageSize') }}: 1920*568</span>
          </div>
          <div v-if="form.promoType === 'MOBILE_BANNER'">
            <span v-if="form.siteId === 1">{{ t('fields.imageSize') }}: 1080*534</span>
            <span v-if="form.siteId === 3">{{ t('fields.imageSize') }}: 1080*675</span>
            <span v-if="form.siteId === 6">{{ t('fields.imageSize') }}: 1080*534</span>
            <span v-if="form.siteId === 7">{{ t('fields.imageSize') }}: 1000*400</span>
          </div>
        </el-form-item>
        <el-form-item :label="t('fields.remark')" prop="remark">
          <el-input
            v-model="form.remark"
            :rows="2"
            type="textarea"
            :placeholder="t('fields.pleaseInput')"
            style="width: 350px"
          />
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="uiControl.dialogVisible = false">
            {{ t('fields.cancel') }}
          </el-button>
          <el-button type="primary" @click="submit">
            {{ t('fields.confirm') }}
          </el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-table
      :data="page.records"
      ref="table"
      row-key="id"
      size="small"
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" />
      <el-table-column prop="displayPath" :label="t('fields.image')">
        <template #default="scope">
          <el-image
            :src="scope.row.displayPath"
            :preview-src-list="[scope.row.displayPath]"
          />
        </template>
      </el-table-column>
      <el-table-column prop="name" :label="t('fields.imageName')" />
      <el-table-column prop="category" :label="t('fields.category')">
        <template #default="scope">
          {{
            uiControl.category.filter(
              item => item.name === scope.row.category
            )[0].display
          }}
        </template>
      </el-table-column>
      <el-table-column prop="siteName" :label="t('fields.site')" />
      <el-table-column prop="createTime" :label="t('fields.createTime')">
        <template #default="scope">
          <span v-if="scope.row.createTime === null">-</span>
          <span
            v-if="scope.row.createTime !== null"
            v-formatter="{
              data: scope.row.createTime,
              timeZone: scope.row.timeZone,
              type: 'date',
            }"
          />
        </template>
      </el-table-column>
      <el-table-column prop="createBy" :label="t('fields.createBy')" />
      <el-table-column prop="updateTime" :label="t('fields.updateTime')">
        <template #default="scope">
          <span v-if="scope.row.updateTime === null">-</span>
          <span
            v-if="scope.row.updateTime !== null"
            v-formatter="{
              data: scope.row.updateTime,
              timeZone: scope.row.timeZone,
              type: 'date',
            }"
          />
        </template>
      </el-table-column>
      <el-table-column prop="updateBy" :label="t('fields.updateBy')" />
      <el-table-column
        type="title"
        :label="t('fields.action')"
        v-if="
          hasPermission(['sys:siteimage:update']) ||
            hasPermission(['sys:siteimage:del'])
        "
      >
        <template #default="scope">
          <el-button
            icon="el-icon-edit"
            size="mini"
            type="success"
            v-permission="['sys:siteimage:update']"
            @click="showEdit(scope.row)"
          />
          <el-button
            icon="el-icon-remove"
            size="mini"
            type="danger"
            v-permission="['sys:siteimage:del']"
            @click="removeImage(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @current-change="changePage"
      layout="prev, pager, next"
      :page-size="request.size"
      :page-count="page.pages"
      :current-page="request.current"
    />
  </div>
</template>

<script setup>
import { ElMessage, ElMessageBox } from 'element-plus'
import { computed, nextTick, onMounted, reactive, ref } from 'vue'
import {
  getSiteImage,
  createSiteImage,
  updateSiteImage,
  deleteSiteImage,
} from '../../../api/site-image'
import { uploadImage } from '../../../api/image'
import { getSiteListSimple } from '../../../api/site'
import { required } from '../../../utils/validate'
import { hasPermission } from '../../../utils/util'
import { useStore } from '../../../store'
import { TENANT } from '../../../store/modules/user/action-types'
import { useI18n } from 'vue-i18n'
import { getPlatformsBySite } from '../../../api/platform'

const { t } = useI18n()
const store = useStore()
const LOGIN_USER_TYPE = computed(() => store.state.user.userType)
const site = ref(null)
const inputImage = ref(null)
const imageForm = ref(null)
const imageDir = process.env.VUE_APP_IMAGE
const categoryList = ['/promo/', '/game/', '/payment/', '/poster/']
const siteList = reactive({
  list: [],
})

const uploadedImage = reactive({
  url: null,
})

const uiControl = reactive({
  dialogVisible: false,
  dialogTitle: '',
  dialogType: 'CREATE',
  editBtn: true,
  removeBtn: true,
  category: [
    { name: 'PROMO', display: t('fields.promo') },
    { name: 'GAME', display: t('menu.Game') },
    { name: 'PAYMENT', display: t('fields.payment') },
    { name: 'POSTER', display: t('fields.poster') },
  ],
  posterType: [
    { name: 'OVERALL', display: t('posterType.overall') },
    { name: 'APP', display: t('posterType.app') },
    { name: 'SPONSOR', display: t('posterType.sponsor') },
    { name: 'GIFT', display: t('posterType.gift') },
    { name: 'COMPETITION', display: t('posterType.competition') },
    { name: 'EVENT', display: t('posterType.event') },
    { name: 'CRYPTO', display: t('posterType.crypto') },
    { name: 'AFFILIATE', display: t('posterType.affiliate') },
  ],
  promoType: [
    { name: 'DESKTOP_IMAGE', display: t('fields.desktopImage') },
    {
      name: 'DESKTOP_BACKGROUND_IMAGE',
      display: t('fields.desktopBackgroundImage'),
    },
    { name: 'MOBILE_IMAGE', display: t('fields.mobileImage') },
    {
      name: 'MOBILE_BACKGROUND_IMAGE',
      display: t('fields.mobileBackgroundImage'),
    },
    { name: 'DESKTOP_BANNER', display: t('fields.desktopBanner') },
    { name: 'MOBILE_BANNER', display: t('fields.mobileBanner') },
    { name: 'TEAM_ICON', display: t('fields.teamIcon') },
    { name: 'OTHER', display: t('fields.other') },
  ],
})

let chooseImage = []
const platform = reactive({
  list: [],
  display: false,
})
const request = reactive({
  size: 30,
  current: 1,
  name: null,
  siteId: null,
  category: null,
})

const form = reactive({
  id: null,
  name: null,
  path: null,
  displayPath: null,
  category: null,
  siteId: null,
  remark: null,
  platform: null,
  posterType: null,
  imageDimension: null,
  promoType: null,
})

const page = reactive({
  pages: 0,
  records: [],
})

const formRules = reactive({
  path: [required(t('message.validateImageRequired'))],
  name: [required(t('message.validateImageNameRequired'))],
  category: [required(t('message.validateCategoryRequired'))],
  siteId: [required(t('message.validateSiteRequired'))],
  platform: [required(t('message.validatePlatformRequired'))],
  posterType: [required(t('messsage.validatePosterTypeRequired'))],
  promoType: [required(t('messsage.validatePromoTypeRequired'))],
})

function resetQuery() {
  request.name = null
  request.siteId = site.value ? site.value.id : null
}

function changePage(page) {
  request.current = page
  loadSiteImage()
}

function showDialog(type) {
  if (type === 'CREATE') {
    if (imageForm.value) {
      imageForm.value.resetFields()
      uploadedImage.url = null
      form.id = null
      platform.list = []
    }
    uiControl.dialogTitle = t('fields.addImage')
  } else {
    uiControl.dialogTitle = t('fields.editImage')
  }
  uiControl.dialogType = type
  uiControl.dialogVisible = true
}

function handleSelectionChange(val) {
  chooseImage = val
  if (chooseImage.length === 0) {
    uiControl.removeBtn = true
  } else if (chooseImage.length === 1) {
    uiControl.removeBtn = false
  } else {
    uiControl.removeBtn = false
  }
}

function showEdit(image) {
  showDialog('EDIT')
  uploadedImage.url = image.displayPath
  nextTick(() => {
    for (const key in image) {
      if (Object.keys(form).find(k => k === key)) {
        form[key] = image[key]
      }
    }
    siteList.list.forEach(element => {
      if (element.siteName === image.siteName) {
        form.siteId = element.id
      }
    })
  })
  checkGamePlatform('EDIT')
}

async function attachPhoto(event) {
  const files = event.target.files[0]

  // record file dimension
  var fr = new FileReader()
  fr.onload = function() {
    var img = new Image()
    img.onload = function() {
      form.imageDimension = img.width + ' * ' + img.height
    }
    img.src = fr.result
  }
  fr.readAsDataURL(files)

  const allowFileType = ['image/jpeg', 'image/png', 'image/gif']
  const dir = 'temp'
  if (!allowFileType.find(ftype => ftype.includes(files.type))) {
    ElMessage({ message: t('message.invalidFileType'), type: 'error' })
  } else {
    var formData = new FormData()
    formData.append('files', files)
    formData.append('dir', dir)
    formData.append('overwrite', false)
    uploadedImage.url = URL.createObjectURL(files)
    return await uploadImage(formData)
  }
}

async function attachImage(event) {
  const data = await attachPhoto(event)
  if (data.code === 0) {
    form.path = data.data
    inputImage.value.value = ''
  } else {
    ElMessage({ message: t('message.failedToUploadImage'), type: 'error' })
  }
}

async function loadSiteImage() {
  const { data: ret } = await getSiteImage(request)
  ret.records.forEach(e => {
    let categoryDir
    switch (e.category) {
      case 'PROMO':
        categoryDir = categoryList[0]
        break
      case 'GAME':
        categoryDir = categoryList[1]
        break
      case 'PAYMENT':
        categoryDir = categoryList[2]
        break
      case 'POSTER':
        categoryDir = categoryList[3]
        break
      default:
        categoryDir = '/temp/'
    }
    e.displayPath = imageDir + categoryDir + e.path

    if (e.category === 'GAME') {
      e.platform =
        e.path.substring(e.path.indexOf('/') + 1, e.path.lastIndexOf('/')) ===
        '/'
          ? null
          : e.path.substring(e.path.indexOf('/') + 1, e.path.lastIndexOf('/'))
    }
  })
  page.pages = ret.pages
  ret.records.forEach(data => {
    data.timeZone =
      store.state.user.sites.find(e => e.siteName === data.siteName) !==
      undefined
        ? store.state.user.sites.find(e => e.siteName === data.siteName)
          .timeZone
        : null
  })
  page.records = ret.records
}

async function loadSites() {
  const { data: site } = await getSiteListSimple()
  siteList.list = site
}

async function removeImage(image) {
  ElMessageBox.confirm(t('message.confirmDelete'), {
    confirmButtonText: t('fields.confirm'),
    cancelButtonText: t('fields.cancel'),
    type: 'warning',
  }).then(async () => {
    if (image) {
      await deleteSiteImage([image.id])
    } else {
      await deleteSiteImage(chooseImage.map(u => u.id))
    }
    await loadSiteImage()
    ElMessage({ message: t('message.deleteSuccess'), type: 'success' })
  })
}

function create() {
  imageForm.value.validate(async valid => {
    if (valid) {
      await createSiteImage(form)
      uiControl.dialogVisible = false
      await loadSiteImage()
      ElMessage({ message: t('message.addSuccess'), type: 'success' })
    }
  })
}

function edit() {
  imageForm.value.validate(async valid => {
    if (valid) {
      await updateSiteImage(form)
      uiControl.dialogVisible = false
      await loadSiteImage()
      ElMessage({ message: t('message.editSuccess'), type: 'success' })
    }
  })
}

function submit() {
  if (form.category === 'PROMO') {
    formRules.promoType = [required(t('message.validatePromoTypeRequired'))]
  } else {
    formRules.promoType = null
  }
  if (uiControl.dialogType === 'CREATE') {
    create()
  } else {
    edit()
  }
}

async function checkGamePlatform(type) {
  if (form.category && form.siteId && form.category === 'GAME') {
    const { data: ret } = await getPlatformsBySite(form.siteId)
    platform.list = ret
    platform.display = true
    formRules.posterType = null
    formRules.platform = [required(t('message.validatePlatformRequired'))]
  } else if (form.category === 'POSTER') {
    formRules.platform = null
    formRules.posterType = [required(t('message.validatePosterTypeRequired'))]
  } else {
    platform.list = []
    platform.display = false
    formRules.posterType = null
    formRules.platform = null
  }
  if (type !== 'EDIT') {
    form.platform = null
  }
}

onMounted(async () => {
  await loadSites()
  if (LOGIN_USER_TYPE.value === TENANT.value) {
    site.value = siteList.list.find(
      s => s.siteName === store.state.user.siteName
    )
    request.siteId = site.value.id
  }
  await loadSiteImage()
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.search {
  display: flex;
  justify-content: flex-start;
}

.btn-group {
  margin-top: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.el-table--enable-row-transition .el-table__body td.el-table__cell {
  padding: 4px 0;
}

#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview .el-image {
  width: 60%;
  height: 60%;
}
.el-table__body .el-image {
  width: 100px;
  background-color: #c1c1c1;
}
td.el-table__cell img {
  width: 100px;
  height: 100px;
}
</style>
